import 'fast-text-encoding';
import React, {useState} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Routes as Switch} from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Home from './components/home/Home';
import About from './components/about/About';
import Services from './components/services/Services';
//import Projects from './components/projects/Projects';
// import Project from './components/projects/Project';
import Blog from './components/blog/Blog';
import Post from './components/blog/Post';
import AddPost from './components/blog/AddPost';
import Error404 from './components/error/Error404';
import Login from './components/common/Login';
import {useEffect} from 'react';
import AuthContext from "./components/context/UserContext";
import Terms from "./components/common/terms&service"
import {Provider} from "react-alert"

const AlertTemplate = ({style, options, message, close}) => (
  <div className={`alert alert-${options.type}`} style={style}>
    {message}
    <button className='alertclose' onClick={close}>close</button>
  </div>
)
const options = {
  timeout: 10000,
  position: "top center"
}





function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setCurrentUser(user);
    }
  }, [])



  return (
    <Provider template={AlertTemplate} {...options}>
      <AuthContext.Provider value={currentUser}>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/services" element={<Services />} />
            {/*<Route exact path="/projects" element={<Projects />} />*/}
            {/* <Route path="/projects/:id" element={Project} /> */}
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/blog/:title" element={<Post />} />
            {currentUser ? <Route exact path="/addPost" element={<AddPost />} /> :
              <Route exact path="/Login" element={<Login />} />}
            <Route exact path="/Login" element={<Login />} />
            <Route path='/*' element={<Error404 />} />
          </Switch>
          <Footer />
        </Router>
      </AuthContext.Provider>
    </Provider >
  );
}

export default App;
