// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database"
import {getAuth} from "firebase/auth"
import {getStorage} from 'firebase/storage';




const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getDatabase()
export const auth = getAuth(app);
export const storage = getStorage(app);

//import {initializeApp} from "firebase/app";
//import {getFirestore} from "firebase/firestore";
//import {getAuth} from "firebase/auth";
//import {getStorage} from 'firebase/storage';

//const firebaseConfig = {
//  apiKey: process.env.REACT_APP_API_KEY,
//  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//  projectId: process.env.REACT_APP_PROJECT_ID,
//  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//  appId: process.env.REACT_APP_APP_ID
//};

//// Initialize Firebase
//export const app = initializeApp(firebaseConfig);
//export const db = getFirestore(app);
//export const auth = getAuth(app);
//export const storage = getStorage(app);