import React, { useState } from 'react'
import Form from '../common/ContactForm'
import "./about.css"

const About = () => {
    const [more, setMore] = useState(false)
    const [showModal, setShowModal] = useState(false)


    const showForm = () => {
        window.scrollTo(0, 0)
        setShowModal(true)
    }
    const screenSize = window.innerWidth
    const closeForm = () => {
        setShowModal(false)
    }
    return (
        <div className='aboutConatainer'>
            <div className='aboutus'>
                {showModal ? <Form closeForm={closeForm} /> : null}
                <h1>About Us</h1>
                <p>
                    Sapphire-blue Software Empire Limited is a software development company that provides innovative software solutions to businesses and individuals.
                </p>
                <button onClick={showForm} className='landingb' id='blue'>Request Qoute</button>
            </div>
            <div className='aboutservice'>
                <h1>Our Service</h1>
                {screenSize < 700 && !more ?
                    <ol>
                        <li>
                            Web development: We specialize in building custom websites that are visually appealing, user-friendly, and optimized for search engines.
                        </li>
                        <li>
                            Mobile app development: We create mobile apps for iOS and Android platforms that are intuitive, engaging, and meet the unique needs of your business
                        </li>
                        {!more && (<p onClick={() => setMore(true)}>Read More...</p>)}
                        <button onClick={showForm} className='landingb' id='trans'>Contact Us</button>
                    </ol>
                    : <ol>
                        <li>
                            Web development: We specialize in building custom websites that are visually appealing, user-friendly, and optimized for search engines.
                        </li>
                        <li>
                            Mobile app development: We create mobile apps for iOS and Android platforms that are intuitive, engaging, and meet the unique needs of your business
                        </li>
                        <li>
                            Custom software solutions: We develop custom software solutions tailored to your specific business requirements, from small tools to large enterprise systems.
                        </li>
                        <li>
                            UI/UX design: We offer UI/UX design services to create intuitive and visually appealing user interfaces that enhance user experience and engagement.
                        </li>
                        <li>
                            Software consulting: Our experts can provide software consulting services to help you make informed decisions about software design, architecture, and technology choices.
                        </li>
                        <li>
                            Testing and quality assurance: We use industry-standard testing and quality assurance methodologies to ensure that your software is bug-free, reliable, and meets your quality standards.
                        </li>
                        <li>
                            Maintenance and support:
                            We provide ongoing maintenance and support services to ensure that your software runs smoothly and stays up-to-date with the latest technologies and security standards
                        </li>
                        <button onClick={showForm} className='landingb' id='trans'>Contact Us</button>
                    </ol>
                }
            </div>
            <div className='aboutus'>
                <h1>spacialtiess</h1>
                <p>
                    We specialize
                    in web development, mobile app development, and custom software solutions that are user-friendly and visually appealing. Our commitment to
                    exceptional customer service and support sets us apart.
                </p>
                <p>
                    We take pride in delivering high-quality software that exceeds our clients' expectations!!!.
                </p>
                <button onClick={showForm} className='landingb' id='blue'>Request Qoute</button>
            </div>
        </div>
    )
}

export default About
