import React, { useState } from 'react';
import { auth } from '../firebasekeys';
import { signInWithEmailAndPassword } from "firebase/auth"
import { useNavigate } from 'react-router-dom';
import "./common.css"
import { ClipLoader } from 'react-spinners';
import { useAlert } from "react-alert";




function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [spiner, setSpiner] = useState(false)
    const alert = useAlert()




    const handleLogin = () => {
        if (!email || !password) {
            alert.error('Kindly check your email & password and try again', { type: 'error', timeout: 7000 })
            setSpiner(false);
        }

        else {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    localStorage.setItem("user", JSON.stringify(user));
                    setSpiner(false)
                    navigate('/addPost');
                    window.location.reload();
                    alert.success("Login  successfully", { type: "success", timeout: 7000 })
                })
                .catch((error) => {
                    setSpiner(false)
                    alert.error(error.message === "Firebase: Error (auth/internal-error)." ? "Kindly check your network conection and try again" : "Kindly Check your PASSWORD & EMAIL address", { type: 'error', timeout: 7000 });
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                });
        }
    };

    return (
        <div className='login'>

            <form >
                <h3>LOGIN WITH EMAIL</h3>
                {spiner ? <div className='spinner'>
                    <ClipLoader color={'#123abc'} loading={true} />
                </div> : null}
                <div className='form-group' id='login'>

                    <input placeholder='Email' type="email" value={email} onChange={(event) => setEmail(event.target.value)} />

                    <input placeholder='Password' type="password" value={password} onChange={(event) => setPassword(event.target.value)} />

                </div>
                <div className="col">
                    <button type="button" onClick={() => {
                        setSpiner(true)
                        handleLogin()
                    }} className="">Submit</button>

                </div>
            </form>
        </div>
    );
}

export default Login;
