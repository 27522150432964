import React, { useState } from "react";
import slider2 from "../../images/slider2.jpg"
import slider3 from "../../images/slider3.jpg"
import slider4 from "../../images/slider4.jpg"
import slider5 from "../../images/slider5.jpg"


const ImageSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        { image: slider2 },
        { image: slider3 },
        { image: slider4 },
        { image: slider5 },
    ];

    const prevSlide = () => {
        const newIndex = currentSlide === 0 ? slides.length - 1 : currentSlide - 1;
        setCurrentSlide(newIndex);
    };

    const nextSlide = () => {
        const newIndex = currentSlide === slides.length - 1 ? 0 : currentSlide + 1;
        setCurrentSlide(newIndex);
    };

    return (
        <div className="slider">
            <img src={slides[currentSlide].image} alt={`Slide ${currentSlide}`} />
            <button onClick={prevSlide}>Prev</button>
            <button onClick={nextSlide}>Next</button>
        </div>
    );
};
export default ImageSlider