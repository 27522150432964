import React from 'react'
import "./common.css"
import logo from "../../Assets/logo/logo.png"
import twi from "../../images/twi.png"
import insta from "../../images/insta.png"
import whatsapp from "../../images/whatsapp.jpg"
import linkedin from "../../images/linkedin.png"
import face from "../../images/face.png"
import {useNavigate} from "react-router-dom";




const Footer = () => {
  const navigate = useNavigate()
  const year = new Date().getFullYear()
  return (
    <div className='footercontainer'>
      <div className='contactIcons'>
        <img className='flogo' src={logo} alt="logo" />
        <div className='cicons'>
          <a href='https://twitter.com/cjhansom_cj'><img src={twi} alt="logo" /></a>
          <img src={insta} alt="logo" />
          <a href='https://chat.whatsapp.com/Bs9AXcr20DU5K2jl64hA4w'> <img src={whatsapp} alt="logo" /></a>
          <a href='https://www.linkedin.com/in/sapphire-bue-software-empire-limited/'><img src={linkedin} alt="logo" /></a>
          <a href='https://www.facebook.com/profile.php?id=100087989894112'><img src={face} alt="logo" /></a>
        </div>
      </div>
      <div className='line'></div>
      <div className='contactIcons'>
        <div className='cicons' >
          <p onClick={() => navigate("/terms")}><a>Terms of Service</a></p>
          <p onClick={() => navigate("/terms")}><a href='#'>Privacy Policy</a></p>
          <p onClick={() => navigate("/terms")}><a href='#'>T.A.Q</a></p>
        </div>
        <p onClick={() => navigate("/terms")}><a>Copy Right &copy; {year} Sapphire-blue Software Empire Limited. All Rigt Reserved</a></p>
      </div>
    </div>
  )
}

export default Footer

