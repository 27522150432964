import React from 'react'
import "./common.css"


const AdvertCard = ({ imageUrl, clicked }) => {
    const style = {
        cardContainer: {
            backgroundPosition: "center",
            backgroungRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(${imageUrl})`,
            display: "flex",
            flexDirection: "column",
            color: "white",
            borderRadius: "10px",
            boxShadow: "0.5px 0px 1px 1px white",
            transition: "0.3s",
            height: "170px",
            width: "95%",
            position: "relative",
            marginBottom: "35px",
        }

    }
    return (
        <div onClick={clicked} style={style.cardContainer}>
        </div>
    )
}

export default AdvertCard
