
import {Link} from 'react-router-dom';

const SearchModel = (props) => {
  const posts = Object.values(props.value)
  return (
    <div className='modal'>
      <div className='postTitle' id='related'><p>Items found {posts.length}</p></div>
      {posts.map((item, key) => {
        return (
          <div className='relateditems' id='' key={key}>

            <div onClick={() => window.location.reload()} className='releteditemtext'>
              <Link to={`/blog/${item.title}`} ><h4>{item.title}</h4></Link>
              {/* <p>{item.description}</p> */}
            </div>
            {/* {imgSrc && <img src={imgSrc[1]} alt={`${item.text}`} />} */}
          </div>
        );
      })}
      <div className="col">

        <button type="button" onClick={() => {
          props.onClose()
        }} className="hbutton">Cancel</button>
      </div>
    </div>
  )
}

export default SearchModel