
import React, {useState} from "react";
import {useAlert} from "react-alert";
import "./common.css";
import {phone_regex, email_regex} from "./expressions"





const Form = (props) => {
  const alert = useAlert()
  const [input, setInput] = useState({
    fName: "",
    lName: "",
    Email: "",
    phone: "",
    message: ""
  })
  //gets input values and send them to contactform on google drive
  const handleClick = async () => {
    let currentDate = new Date().toJSON().slice(0, 20);
    try {
      if (!input.fName) throw new Error('Kindly provide your legal first name');
      if (!input.lName) throw new Error('Kindly provide your legal last name');
      if (!phone_regex.test(input.phone))
        throw new Error('A valid phone number is required');
      if (!email_regex.test(input.Email))
        throw new Error('A valid email address is required');
      if (!input.message) throw new Error('Kindly inclue a message of on what you want to do');

      const response = await fetch("https://v1.nocodeapi.com/sapphire/google_sheets/kYNCgurhDDweTbuX?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify([[input.fName, input.lName, input.phone, input.Email, input.message, currentDate]
          ])
        }
      )

      await response.json()
      props.closeForm()
      alert.success("your message has been submited successfully", {type: "success", timeout: 7000})
    } catch (error) {
      alert.error(error.message === "Failed to fetch" ? "Kindly Check your Network Connection and try again!" : error.message, {type: 'error', timeout: 7000});
      console.log(" err ====>", error);
    }
  }

  return (
    <form>
      <h3>CONTACT FORM</h3>
      <p>How can we be of service to you ?</p>
      <div className="form-group">
        <input onChange={e =>
          setInput({
            ...input,
            fName: e.target.value,
          })
        } placeholder="first Name" type="text" />
        <input onChange={e =>
          setInput({
            ...input,
            lName: e.target.value,
          })
        } placeholder="last Name" type="text" />
        <input onChange={e =>
          setInput({
            ...input,
            phone: e.target.value,
          })
        } placeholder="phone" type="tel" />
        <input onChange={e =>
          setInput({
            ...input,
            Email: e.target.value,
          })
        } placeholder="Email" type="text" />
        <textarea id="te" onChange={e =>
          setInput({
            ...input,
            message: e.target.value,
          })
        } placeholder="message" type="text" />
      </div>
      <div className="col">
        <button type="button" onClick={() => {
          handleClick()
        }} className="">Submit</button>
        <button type="button" onClick={() => {
          props.closeForm()
        }} className="hbutton">Cancel</button>
      </div>
    </form>
  )
}

export default Form