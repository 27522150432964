const terms = [
  {
    "topic": "General Terms",

    "items": [
      "Company Name: The company providing software development services is Sapphire Blue Software Empire Limited."
      , "Payments: All fees are to be paid through card, bank transfer or other payment methods specified in the billing format for each software product or service. No physical cash payment is accepted.  Any Payments made as fee for contract or contract materials are not refundable after 5 days of payment. Payments can be split into up to 3 installments for contracts, or as specified by the product being used.",
      "Warranty: Sapphire Blue Software Empire Limited warrants that the software developed will be free from defects for a period of 90 days from the date of delivery.",
      "Intellectual Property: All software products produced by Sapphire Blue Software Empire Limited are owned by Sapphire Blue Software Empire Limited. Clients are granted a non-exclusive, non-transferable license to use the software products for their intended purpose.",
      "Confidentiality: Sapphire Blue Software Empire Limited and the client will maintain the confidentiality of any proprietary or confidential information provided to each other during the course of providing or receiving software development services.",
      "Limitation of Liability: Sapphire Blue Software Empire Limited shall not be liable for any damages arising from the use or inability to use the software products provided, including but not limited to direct, indirect, incidental, or consequential damages.",
      "Governing Law: These Terms and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of Nigeria. If the client resides outside Nigeria, the international law guiding trade and services will apply.",
      "Contract Agreements: All details of contracts must be documented in a contract form and signed by both parties. Clients must agree to the terms of the contract and follow them strictly. Any breach of the contract terms may lead to the termination of the contract and forfeiture of any fees paid."
    ]
  },
  {
    "topic": "Specific Terms for Clients",
    "items": [
      "Requirements: Clients must provide Sapphire Blue Software Empire Limited with clear and specific requirements for the software product to be developed.",
      "Changes: Any changes to the requirements after the start of development may incur additional fees.",
      "Delivery: Sapphire Blue Software Empire Limited will provide an estimated delivery date for the software product to be developed. The delivery date may be subject to change due to unforeseen circumstances.",
      "Testing: Sapphire Blue Software Empire Limited will test the software product to ensure it meets the agreed-upon requirements before delivery.",
      "Maintenance: Sapphire Blue Software Empire Limited offers maintenance services for the software product after delivery. Maintenance fees will be determined based on the scope of work required."
    ]
  }
]



const Terms = () => {

  return (
    <div className="terms">
      <h1>Software Development Services</h1>
      {terms.map((item, key) => {
        return (
          <div className="termsservice" key={key}>
            <h3>{item.topic}</h3>
            {item.items?.map((i, a) => <ol key={a}><li>{i}</li></ol>)}
          </div>
        );
      })}
    </div>

  )

}


export default Terms