import React from 'react'
import "./common.css"


const Card = ({ title, imageUrl, description, imagelogo }) => {
    const style = {
        cardContainer: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0.5px 0px 1px 1px white",
            transition: "0.3s",
            height: "270px",
            width: "95%",
            position: "relative"
        },
        imageUrl: {
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: "center",
            backgroungRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
            opacity: "0.2",
            content: "''",
            top: 0,
            left: 0,
            zIndex: "1",
            position: "absolute"
        },

        title: {
            fontSize: "2rem",
            fonWeight: "bold",
            marginBottom: "10px",
        },
        description: {
            fontSize: "1.2rem"
        },
        image: {
            width: "40px",
            height: "40px",
            top: 0,
            left: 0
        },


    }
    return (
        <div style={style.cardContainer}>
            <img style={style.image} src={imagelogo} alt="logo" />
            <div style={style.imageUrl}>{imageUrl}</div>
            <h1 style={style.title}>{title}</h1>
            <p style={style.description}>{description}</p>
        </div>
    )
}

export default Card
