import React from "react";
import "./services.css"
import Card2 from "../common/Card2"
import logo from "../../Assets/logo/logo.png"

const services = [
    {
        name: "Web Development",
        description: "We build high-quality web applications using the latest technologies.",
        stack: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
    },
    {
        name: "Mobile App Development",
        description: "We develop mobile apps for iOS and Android using native and hybrid technologies.",
        stack: ["Swift", "Java", "React Native", "Ionic"],
    },
    {
        name: "UI/UX Design",
        description: "We design beautiful and user-friendly interfaces for web and mobile applications.",
        stack: ["Figma", "Sketch", "Adobe XD"],
    },
    {
        name: "Cloud Computing",
        description: "We provide cloud solutions to help businesses scale and optimize their operations.",
        stack: ["AWS", "Azure", "Google Cloud"],
    },
    {
        name: "Artificial Intelligence",
        description: "We build AI solutions that help businesses automate processes and gain insights from data.",
        stack: ["Python", "TensorFlow", "PyTorch", "Scikit-Learn"],
    },
    {
        name: "Blockchain Development",
        description: "We develop blockchain solutions for businesses that require secure and transparent data management.",
        stack: ["Solidity", "Ethereum", "Hyperledger Fabric"],
    },
    {
        name: "DevOps",
        description: "We provide DevOps services to help businesses streamline their software development and deployment processes.",
        stack: ["Docker", "Kubernetes", "Jenkins", "Git"],
    },
];

function ServicesList() {
    return (
        <div className="servicecontainer">
            <h1>Our Services</h1>
            <div className="services">
                {services.map((service, index) => (
                    <Card2 imagelogo={logo}
                        title={service.name}
                        description={service.description}
                        key={index}
                    />
                ))}
            </div>
        </div>
    );
}

export default ServicesList;
