import React from 'react'
import "./error.css"

const Error404 = () => {
    return (
        <div className='errorcontainer'>
            <h1>404 ERROR</h1>
            <p>Sorry this page could not be found or currently under maintenance!!!</p>
        </div>
    )
}

export default Error404