import React, {useState, useEffect, useMemo} from 'react';
import {db} from "../firebasekeys"
import {ref, onValue} from 'firebase/database';
import "./Blog.css"
import SliderImage from "../common/Slider"
import AdvertCard from "../common/AdvertCard"
import ContactForm from "../common/ContactForm"
import {ClipLoader} from 'react-spinners';
import ShareButtons from '../common/SocialShare';
import learnin1 from "../../images/learnin1.png"
import learning2 from "../../images/learning2.png"
import dev3month1 from "../../images/dev3month1.png"
import {Link} from 'react-router-dom';
import Search from './search';
import SearchModel from './searchModel';
import {Helmet} from 'react-helmet';



const Blog = () => {
  const [posts, setPosts] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [showpost, setShowpost] = useState()
  const [showForm, setShowForm] = useState(false)
  const categories = ["General", "Technology", "Programming", "Design"];
  //const url = "sapphireblue.ng"
  const url = "https://sapphirebluesoftware.ng/blog/Introducing%20Sapphire%20Blue%20Blog%202.0:%20Your%20Go-To%20Source%20for%20Wildlife,%20Politics,%20Health,%20Sports,%20Technology%20and%20Education%20News"
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);

  //  localStorage.clear()
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("posts"));
    const fetchPosts = async () => {
      try {
        const postsRef = ref(db, "/blog-posts");
        const postsData = await new Promise((resolve, reject) =>
          onValue(postsRef, (snapshot) => resolve(snapshot.val()), reject)
        );
        if (postsData) {
          const latestPostData = Object.values(postsData).splice(-10);
          const deleteTime = new Date().getTime() + 20 * 60 * 60 * 1000;
          localStorage.setItem(
            "posts",
            JSON.stringify({posts: latestPostData, deleteTime: deleteTime})
          );
          setShowpost(latestPostData[latestPostData.length - 1]);
          setPosts(latestPostData);
          return latestPostData;
        } else {
          throw new Error("Data not found");
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    try {
      if (storedData && storedData.posts.length !== 0) {
        const deleteTime = storedData.deleteTime;
        if (new Date().getTime() > deleteTime) {
          localStorage.removeItem("posts");
          fetchPosts().then(setPosts);
        } else {
          setShowpost(storedData.posts[storedData.posts.length - 1]);
          setPosts(storedData.posts);
        }
      } else {
        fetchPosts().then(setPosts);
      }

    } catch (error) {

      console.log(error.message);
    }
  }, []);

  // get the first image in post to be added to sharelinks
  const firstImageUrls = useMemo(() => {
    return showpost?.subtitles?.map((subtitle) => {
      const paragraph = subtitle.paragraphs.join("");
      const imgSrcMatch = paragraph.match(/<img[^>]*src=["']([^"']*)["'][^>]*>/);
      if (imgSrcMatch) {
        const imgSrc = imgSrcMatch[1];
        return imgSrc;
      } else {
        return null;
      }
    }).filter(Boolean).map(url => url)[1];
  }, [showpost]);
  console.log("here=====>", firstImageUrls);
  //handle category
  const handleCategoryClick = (category) => {
    if (category === expandedCategory) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(category);
    }
  };
  const sh = () => {
    window.scrollTo(0, 0)
    setShowForm(true)
  }
  const closeForm = () => setShowForm(false)

  const handleSearch = (value) => {
    setSearchValue(value);
    setShowModal(true);
  }
  const closemodel = () => setShowModal(false)

  //share button
  return (
    <div className="container">
      <Helmet>
        <title>{showpost?.title}</title>
        <meta name="description" content={showpost?.description} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={showpost?.title} />
        <meta property="og:description" content={showpost?.description} />
        <meta property="og:image" content={firstImageUrls} />
      </Helmet>
      {showForm ? <ContactForm closeForm={closeForm} /> : null}
      <Search onSearch={handleSearch} />
      {showModal && <SearchModel value={searchValue} onClose={closemodel} />}

      <div className="postcontainer">
        {!showpost && !posts ?
          <div className='spiner'>
            <ClipLoader color={'#123abc'} loading={true} />
          </div> :
          <>

            <div className="left">
              <div className="postCategory">
                <h3>Post Categories</h3>
                {categories.map((category) => (
                  <div key={category}>
                    <p onClick={() => handleCategoryClick(category)}>
                      {category} ({posts?.filter((post) => post.category === category).length})
                    </p>
                    {expandedCategory === category && (
                      <ol>
                        {posts
                          .filter((post) => post.category === category)
                          .map((post, key) => {
                            return (
                              <Link key={key} to={`/blog/${post.title}`} >{post.title}</Link>
                            )
                          })}
                      </ol>
                    )}
                  </div>
                ))}
              </div>
              <div className='leftad'>
                <AdvertCard clicked={sh} imageUrl={learning2} />
              </div>
            </div>
            <div className="middle">
              <div className='contractAd'>
                <SliderImage />
                <p onClick={sh}>Contact us now for a quote on how to expand your business visibility online!!!</p>
              </div>
              <div className='postmaintitle'><h1>{showpost.title}</h1></div>
              <div>
                {showpost.subtitles.map((item, key) => {
                  const toString = item.paragraphs.join('')
                  const paragraph = toString.replace(/<img[^>]*src=["']([^"']*)["'][^>]*>/g, '')
                  const imgSrc = toString.match(/<img[^>]*src=["']([^"']*)["'][^>]*>/g)
                  return (
                    <div className='postContent' key={key}>
                      <p className='postTitle' >{item.text}</p >
                      <p>{paragraph}</p>
                      {imgSrc && <img src={imgSrc[0].match(/src="([^"]+)"/)[1]} alt={`${item.text}  `} />}
                    </div>
                  );
                })}
              </div>
              <div className='sharepost'>
                <p>Did you enjoy reading our post? Kindly share!!!</p>
                <ShareButtons url={url} title={showpost.title} description={showpost.description} imageUrl={firstImageUrls} />
              </div>
            </div>
            <div className="right">
              <AdvertCard clicked={sh} imageUrl={dev3month1} />
              <AdvertCard clicked={sh} imageUrl={learnin1} />
              <AdvertCard clicked={sh} imageUrl={learning2} />
            </div>
          </>}
      </div>
    </div>
  );
}

export default Blog;
