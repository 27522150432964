
//import React from "react";
//import {InlineReactionButtons} from 'sharethis-reactjs';
//import {InlineShareButtons} from 'sharethis-reactjs';
//import {StickyShareButtons} from 'sharethis-reactjs';
//import {InlineFollowButtons} from 'sharethis-reactjs';

//const ShareButton = ({url, title, description, imageUrl}) => {

//  return (
//    <div>
//      <style dangerouslySetInnerHTML={{
//        __html: `
//          html, body {
//            margin: 0;
//            padding: 0;
//            text-align: center;
//          }
//          h1 {
//            font-size: 24px;
//            font-weight: bold;
//          }
//          hr {
//            margin-bottom: 40px;
//            margin-top: 40px;
//            width: 50%;
//          }
//        `}} />

//      <InlineShareButtons
//        config={{
//          alignment: 'center',  // alignment of buttons (left, center, right)
//          color: 'social',      // set the color of buttons (social, white)
//          enabled: true,        // show/hide buttons (true, false)
//          font_size: 16,        // font size for the buttons
//          labels: 'cta',        // button labels (cta, counts, null)
//          language: 'en',       // which language to use (see LANGUAGES)
//          networks: [           // which networks to include (see SHARING NETWORKS)
//            'whatsapp',
//            'linkedin',
//            "google",
//            "instagram",
//            'facebook',
//            'twitter'
//          ],
//          padding: 12,          // padding within buttons (INTEGER)
//          radius: 4,            // the corner radius on each button (INTEGER)
//          show_total: true,
//          size: 40,             // the size of each button (INTEGER)

//          // OPTIONAL PARAMETERS
//          url: url, // (defaults to current url)
//          image: imageUrl,  // (defaults to og:image or twitter:image)
//          description: description,       // (defaults to og:description or twitter:description)
//          title: title,            // (defaults to og:title or twitter:title)
//          message: description,     // (only for email sharing)
//          subject: title,  // (only for email sharing)
//          username: "sapphire blue software empire limited" // (only for twitter sharing)
//        }}
//      />
//      <hr />

//      <InlineReactionButtons
//        config={{
//          alignment: 'center',  // alignment of buttons (left, center, right)
//          enabled: true,        // show/hide buttons (true, false)
//          language: 'en',       // which language to use (see LANGUAGES)
//          min_count: 0,         // hide react counts less than min_count (INTEGER)
//          padding: 12,          // padding within buttons (INTEGER)
//          reactions: [          // which reactions to include (see REACTIONS)
//            'slight_smile',
//            'heart_eyes',
//            'laughing',
//            'astonished',
//            'sob',
//            'rage'
//          ],
//          size: 48,             // the size of each button (INTEGER)
//          spacing: 8,           // the spacing between buttons (INTEGER)

//          // OPTIONAL PARAMETERS
//          url: url // (defaults to current url)
//        }}
//      />
//      <hr />

//      <InlineFollowButtons
//        config={{
//          action: 'Follow us:', // call to action (STRING)
//          action_enable: true,  // show/hide call to action (true, false)
//          action_pos: 'bottom', // position of call to action (left, top, right)
//          alignment: 'center',  // alignment of buttons (left, center, right)
//          color: 'white',       // set the color of buttons (social, white)
//          enabled: true,        // show/hide buttons (true, false)
//          networks: [           // which networks to include (see FOLLOW NETWORKS)
//            'twitter',
//            'facebook',
//            'instagram',
//          ],
//          padding: 8,           // padding within buttons (INTEGER)
//          profiles: {           // social profile links for buttons
//            twitter: 'sapphire-blue software empire limited',
//            facebook: 'sapphire_blue software empire limited',
//            instagram: 'sapphire-blue software empire limited',
//          },
//          radius: 9,            // the corner radius on each button (INTEGER)
//          size: 32,             // the size of each button (INTEGER)
//          spacing: 8            // the spacing between buttons (INTEGER)
//        }}
//      />
//      <hr />

//      <StickyShareButtons
//        config={{
//          alignment: 'left',    // alignment of buttons (left, right)
//          color: 'social',      // set the color of buttons (social, white)
//          enabled: true,        // show/hide buttons (true, false)
//          font_size: 16,        // font size for the buttons
//          hide_desktop: false,  // hide buttons on desktop (true, false)
//          labels: 'counts',     // button labels (cta, counts, null)
//          language: 'en',       // which language to use (see LANGUAGES)
//          min_count: 0,         // hide react counts less than min_count (INTEGER)
//          networks: [           // which networks to include (see SHARING NETWORKS)
//            'linkedin',
//            'facebook',
//            'twitter',
//            'email',
//            "whatsapp"
//          ],
//          padding: 12,          // padding within buttons (INTEGER)
//          radius: 4,            // the corner radius on each button (INTEGER)
//          show_total: true,     // show/hide the total share count (true, false)
//          show_mobile: true,    // show/hide the buttons on mobile (true, false)
//          show_toggle: true,    // show/hide the toggle buttons (true, false)
//          size: 48,             // the size of each button (INTEGER)
//          top: 160,             // offset in pixels from the top of the page

//          // OPTIONAL PARAMETERS
//          url: url, // (defaults to current url)
//          image: imageUrl,  // (defaults to og:image or twitter:image)
//          description: description,       // (defaults to og:description or twitter:description)
//          title: title,            // (defaults to og:title or twitter:title)
//          message: description,     // (only for email sharing)
//          subject: title,  // (only for email sharing)
//          username: "sapphire-blue software empire limited" // (only for twitter sharing)

//        }}
//      />

//    </div>
//  );

//};

//// export
//export default ShareButton;
import React from 'react';
import {FacebookShareButton, TwitterShareButton} from 'react-share';

const ShareButtons = ({url, title}) => {
  return (
    <div>
      <FacebookShareButton url={url}>
        <img src="/facebook.png" alt="Share on Facebook" />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <img src="/twitter.png" alt="Share on Twitter" />
      </TwitterShareButton>
    </div>
  );
}

export default ShareButtons;
