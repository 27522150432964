import React from 'react'
import "./common.css"


const Card2 = ({title, description, imagelogo, clicked}) => {
  const style = {
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "170px",
      width: "300px",
      backgroundColor: "white",
      color: "#395ac9",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px 0px black",
      transition: "0.3s",
      margin: "20px",
      textAlign: "center"
    },
    title: {
      fontSize: "1rem",
      fonWeight: "bold",
      marginBottom: "5px",
      color: "#395ac9",
      margin: 0,
      padding: 0
    },
    description: {
      fontSize: "1.2rem",
      lineHeigt: 1
    },
    image: {
      width: "60px",
      height: "60px",
    },


  }
  return (
    <div onClick={clicked} style={style.cardContainer}>
      <img style={style.image} src={imagelogo} alt="logo" />
      <h1 style={style.title}>{title}</h1>
      <p style={style.description}>{description}</p>
    </div>
  )
}

export default Card2
