import React, { useState } from 'react';
import "./home.css";
import Card from "../common/Card"
import Card2 from "../common/Card2"
import logotr from "../../Assets/logo/logotr.png"
import logo from "../../Assets/logo/logo.png"
import learnin1 from "../../images/learnin1.png"
import Form from "../common/ContactForm"


let item = [
    {
        title: "UI UX Design",
        text: "Our UX team will reseach and come up with great idea's  that will suit your business and your target customers"
    },
    {
        title: "Custom Email",
        text: "Using a custom Email address will ehance trust as your business will be persived as a corprate entity that can be trusted"
    },
    {
        title: "Great Maintaince Culture",
        text: "We will protect your applications from hackers, malware, gugs and downtime while custantly updating your application"
    },
    {
        title: "Speed",
        text: "just like this page you are on now, your pages and applications will load fast and your users wont have to bounce"
    },
    {
        title: "SEO",
        text: "We Will optimize your website so you can easily be visible to search engine"
    },
    {
        title: "Cross-bowser Compatibility",
        text: "All our websites are compatible with any kind of device. mobile phones, laptops or ipad"
    }
]
function Home() {
    const [more, setMore] = useState(false)
    const [showModal, setShowModal] = useState(false)
    // const [showservice, setShowservice] = useState(0)
    // const [count, setcount] = useState(null)
    // const swapLeft = () => {
    //     setShowservice((showservice - 1 + item.length) % item)
    // }
    // const swapRight = () => {
    //     setShowservice((showservice + 1) % item.length)
    // }

    // useEffect(() => {
    //     const interval = setInterval(swapRight, 3000)
    //     return () => clearInterval(interval)
    // }, [swapRight])
    const showmore = () => {
        setMore(true)
    }
    const showForm = () => {
        window.scrollTo(0, 0)
        setShowModal(true)
    }
    const closeForm = () => {
        setShowModal(false)
    }
    return (
        <div className='home'>

            <div className='landingpage'>
                <div className='landingContent'>
                    {showModal ? <Form closeForm={closeForm} /> : null}
                    <h1>Tech Make's Life Easier</h1>
                    <h1>Enhance Your Journey Now!</h1>
                    <div className='landingBTN'>
                        <button onClick={showForm} className='landingb' id='blue'>Request Qoute</button>
                        <button onClick={showForm} className='landingb' id='trans'>Start Training</button>
                    </div>
                </div>
            </div>
            <div className='second'>
                <div className='serviceRender'>
                    <div className='car'>
                        <Card
                            imagelogo={logo}
                            imageUrl={logotr}
                            title={"We Offer the best rate"}
                            description={"We analize, build and maintain custom software applicatons for your business"}
                        />
                    </div>
                    <div className='cardtext'>
                        <p>
                            We are a team of software developers and we are proud to offer a top-quality
                            software development services to businessess like yours. Our company spacializes in
                            in creating custom software solutions tailored to meet your unique needs. Weather your
                            require web or mobile applicatons, software integrations, or anything in between, we've
                            got you covered.
                        </p>
                        <button onClick={showForm} className='landingb' id='trans'>Request Service</button>
                    </div>
                </div>
                <div className='serviceRender'>
                    <img className='learnimage' src={learnin1} alt="learn " />
                    <div className='cardtext' id='tex'>
                        <p>
                            Boost your software development skill with our Training programs!
                            We offer flexible and customised training in programing languages,
                            database management, software testing, and more. Our expirenced trainers
                            provide a supportive learning environment that encourages creativity and innovetion.
                            Contact Us now to start your ever enriching software development Journey.
                        </p>
                        <button onClick={showForm} className='landingb' id='trans'>Start Learning</button>
                    </div>
                </div>
            </div>
            <div className="happyClient">
                <h1>Key features of our Software Development Service</h1>
                <div className='unique'>
                    {item.map((item, key) => {
                        return (<Card2
                            imagelogo={logo}
                            title={item.title}
                            description={item.text}
                            key={key}
                        />)
                    })}
                </div>
            </div>
            <div className='lasttest'>
                <h1>Unlock Your Potential with Our Software Development Solutions</h1>
                {!more ? <p> Are you looking to improve your software development skills or develop software solutions for your business? Look no further than our software development training and services.
                    Our expert software developers have years of experience creating innovative solutions for a variety of industries. We offer comprehensive software development training programs
                    {!more && (<span onClick={showmore}>Read More...</span>)}
                </p>
                    : <p> Are you looking to improve your software development skills or develop software solutions for your business? Look no further than our software development training and services.
                        Our expert software developers have years of experience creating innovative solutions for a variety of industries. We offer comprehensive software development training programs
                        that cover everything from the basics of programming to advanced techniques for building complex applications. Our training courses are designed to be accessible and engaging,
                        regardless of your experience level. And if you need software development services for your business, our team has the skills and expertise to bring your ideas to life.
                        Contact us today to learn more about how our software development training and services can help you unlock your potential in the world of software development.
                    </p>}
                <button onClick={showForm} className='landingb' id='trans'>Message Us</button>
            </div>
        </div>
    );
}

export default Home;
