import React, {useState} from "react"
import {db} from "../firebasekeys"
import {ref, onValue} from 'firebase/database';
import {useAlert} from "react-alert";
import {ClipLoader} from "react-spinners";


const Search = (props) => {
  const [keyword, setKeyword] = useState("")
  const [post, setPost] = useState({})
  const alert = useAlert()
  const [spiner, setSpiner] = useState(false)





  const checkDataBase = () => {
    try {
      if (!keyword) {
        setSpiner(false)
        alert.error("kindly add some keyword to search", {type: 'error', timeout: 7000})
      }
      else {
        const postsRef = ref(db, '/blog-posts');
        onValue(postsRef, (snapshot) => {
          const postsData = snapshot.val();
          const post = Object.values(postsData)
          const realpost = post.filter(item => {return item = item.title.toLowerCase().includes(keyword.toLocaleLowerCase())})
          if (realpost.length > 0) {
            setPost(realpost);
            props.onSearch(realpost);
            setSpiner(false)

          }
          else {
            setSpiner(false)
            alert.info("Post not found", {type: "error", timeout: 7000})
          }
        })
      }
    } catch (error) {
      alert.error(error.message === "Failed to fetch" ? "Kindly Check your Network Connection and try again!" : error.message, {type: 'error', timeout: 7000});
      console.log(error.message);
    }

  }

  return (
    <div className="search-container">
      {
        !spiner ?
          <>
            <input placeholder="Type a keyword. (example... technolog)" type="text" onChange={(e) => setKeyword(e.target.value)} />
            <button onClick={() => {
              checkDataBase()
              setSpiner(true)
            }}>Search</button>
          </> :
          <ClipLoader color={'#123abc'} loading={true} />
      }
    </div>
  )
}


export default Search